.primary {
  border: none;
  margin: 10px;
}

.btnNarrow {
  width: 80%;
}

.naujiena>* {
  -webkit-box-flex: 0.2;
  -ms-flex: 0.2 1;
  flex: 0.2 1;
  justify-content: center;
}

.ql-container.ql-snow {
  height: unset;
}

.inputNaujiena {
  border: 0.5px solid black;
  padding: 10px;
  border-radius: 10px;
  margin: 0 0 10px 0;
  background-color: var(--main-background);
}

textarea {
  border: 0.1px solid black;
  padding: 10px;
  border-radius: 10px;
  margin: 10px 0;
  background-color: var(--main-background);
  font-family: "Roboto", sans-serif;
}

textarea:focus-visible {
  outline: none;
}

.ql-toolbar.ql-snow {
  border: 10px solid black;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.ql-container.ql-snow {
  border: 1px solid black;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* .ReactModal__Content.ReactModal__Content--after-open {
  border-radius: 20px ;
  background-color: var(--main-background) !important;
  width: 50%;
  height: 80%;
  padding: 50px ;
  border: unset !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
} */

.modalTexts>* {
  margin: unset !important;
}

.btn-row {
  margin: 20px 0 0 0;
  width: 30%;
}

label {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: unset !important;
}

textarea {
  resize: none;
}

::-webkit-scrollbar {
  background-color: var(--main-background);
}

::-webkit-scrollbar-thumb {
  background-color: var(--main-color);
  background-clip: content-box;
  border: 2px solid transparent;
}

.ReactModal__Content.ReactModal__Content--after-open::-webkit-scrollbar {
  border-radius: 20px;
  padding: 10px;
  margin: 20px 0;
}

.ReactModal__Content.ReactModal__Content--after-open::-webkit-scrollbar-thumb {
  background-color: #db643e;
  border-radius: 20px;
  background-clip: content-box;
  border: 5px solid transparent;
}

.newsTableBtnCont {
  width: 70%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.modalCont {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.modalCont {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

.navBar {
  background-color: var(--main-background) !important;
  z-index: 100;
}

.navItem {
  color: black;
  cursor: pointer;
}

.navLogo {
  margin: 20px;
  cursor: pointer;
}

.navLogo img {
  height: 50px;
}

.lentele {
  width: 30%;
}

.loginInput {
  width: 90%;
}

label.checkbox {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  /* margin: 0 0 0 20px; */
}

.prisiminti {
  margin: 0 10px 0 0;
}

.loginPassLabel {
  position: relative;
}

.passIcon {
  position: absolute;
  margin: 20px 0 0 0;
  right: 30px;
}

input:focus-visible {
  outline: none;
}




/* 
.react-select__menu {
  background-color: var(--main-color) !important;
} */

.workerCalendar {
  border: unset;
  background: unset;
  font-size: 15px;
  font-weight: 600;
  color: var(--main-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  padding: 5px 0 10px 0;
  margin: 0 0 0 20px !important;
}

.workerCalendar img {
  padding: 0 10px 0 0;
}

.modalTexts label {
  z-index: 0;
}

.editWorkerName {
  margin: 0 0 50px 0;
  font-weight: 700;
  font-size: 30px;
}

.newsName {
  margin: 0;
  font-weight: 700;
  font-size: 30px;
}

.modal .btn-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.mainImg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.newNaujienaBtn img {
  width: 15px;
  padding: 0 10px 0 0;
  object-fit: scale-down;
}

.newNaujienaBtn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 700;
  margin: 5px;
}

.newNaujienaBtn:hover {
  color: var(--main-color);
  cursor: pointer;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 100;
}

.editWorkerModal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.primary {
  border: 1px solid var(--main-color) !important;
}

.primary:hover {
  color: var(--main-color) !important;
  background-color: var(--main-background) !important;
  border: 1px solid var(--main-color) !important;
}

.loginInput {
  border: 0.5px solid black;
  padding: 10px;
  border-radius: 10px;
  margin: 10px 0;
  background-color: var(--main-background);
}

input[type="submit"] {
  width: 95%;
}

.closeBtn {
  cursor: pointer;
}


.ReactModal__Content.ReactModal__Content--after-open.modal:focus-visible {
  outline: none;
}


.loginEmailLabel :focus {
  border-color: var(--main-color);
}

.loginEmailLabel {
  position: relative;
}



.login2Label {
  width: 94%;
  margin: 0 20px;
}

.archyvas {
  margin-top: 0px;
}



.MuiDataGrid-cell.MuiDataGrid-cell--textLeft {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.deleteImg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  cursor: pointer;
}

.deleteImg:hover {
  -webkit-filter: invert(0.5);
  filter: invert(0.5);
}

.deleteImg1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  cursor: pointer;
}

.deleteImg1:hover {
  -webkit-filter: invert(0.5);
  filter: invert(0.5);
}

.deleteImg1 img {
  width: 20%;
}

.MuiDataGrid-cell.MuiDataGrid-cell--textLeft {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.deleteImgNews img {
  width: 26%;
}

.deleteImgNews {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  cursor: pointer;
  width: 80px;
}

.deleteImgNews:hover {
  -webkit-filter: invert(0.5);
  filter: invert(0.5);
}

.modalTextsNews {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* grid-template-columns: 1fr 1fr; */
  /* grid-gap: 10px 100px; */
  /* flex-wrap: wrap; */
  /* justify-content: space-between; */
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.fullFlex {
  -webkit-box-flex: 100%;
  -ms-flex: 100% 1;
  flex: 100% 1;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  /* padding: 0 10px; */
}

.selectedDarbas.row.fullFlex {
  /* border: 2px solid var(--main-color); */
  background-color: unset !important;
  cursor: pointer;
  /* border-radius: 20px; */
}

.MuiDataGrid-row.Mui-selected:focus-visible {
  outline: none;
}

.MuiDataGrid-row.Mui-selected:hover {
  background-color: none;
}

.MuiDataGrid-cell.MuiDataGrid-cell--textLeft:focus {
  outline: none;
}


.btn.disabled {
  background-color: #bfa6a6 !important;
  color: var(--main-background);
  border-color: #bfa6a6 !important;
  pointer-events: none;
}

.btn.disabled:hover {
  background-color: #bfa6a6 !important;
  color: var(--main-background) !important;
  border-color: #bfa6a6 !important;
  cursor: context-menu;
}

.btn.disabled2 {
  background-color: #908f8a !important;
  color: var(--main-background);
  border-color: #908f8a !important;
  pointer-events: none;
}

.btn.disabled2:hover {
  background-color: #908f8a !important;
  color: var(--main-background) !important;
  border-color: #908f8a !important;
  cursor: context-menu;
}

.reverse {
  background-color: var(--main-background) !important;
  color: var(--main-color);
}

.calendarContainer {
  max-width: 318px;
  /* max-height: 205px; */
  border: 1px solid var(--main-background) !important;
  border-radius: 30px;
  padding: 10px;
  margin: 10px 0px 0 0;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  color: var(--main-background) !important;
}

button.react-calendar__navigation__label {
  color: var(--main-background) !important;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  color: var(--main-background) !important;
}

button.react-calendar__tile.react-calendar__month-view__days__day {
  color: var(--main-background);
}

button.react-calendar__tile.react-calendar__month-view__days__day:hover {
  color: var(--main-color);
}

button:disabled {
  color: rgba(143, 75, 54, 0.3) !important;
}

button:disabled:hover {
  color: rgba(143, 75, 54, 0.3) !important;
}

.ReactModal__Content.ReactModal__Content--after-open {
  background-color: var(--main-background) !important;
}

.ham {
  display: none;
}

.navContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0 30px 0 0;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}



.deleteon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.kablukassiauriau {
  width: 20% !important;
}

.activeNavBar {
  color: var(--main-color);
}

.navItem:active {
  color: var(--main-color);
}

.navItem:hover {
  color: var(--main-color);
}

.mainarch {
  margin-top: 2%;
  background-color: var(--main-background);
}

.active {
  color: var(--main-color);
}

.pasirinktiNarow {
  width: 30% !important;
}

.selected {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}


.userPicker label {
  background-color: var(--main-background);
}

.userPicker .selected {
  background-color: unset;
}

.userPicker>div>div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
}

.MuiDataGrid-columnHeaderTitleContainer {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.MuiDataGrid-cell.MuiDataGrid-cell--textLeft:focus {
  outline: unset !important;
  border: unset;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex !important;
  width: 100%;
  flex-direction: row !important;
}

.row2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex !important;
  width: 100%;
  flex-direction: row !important;
}

img.logout {
  height: 26px;
  padding: 0 0 0 10px;
}

.logout {
  cursor: pointer;
}

#root {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  /* justify-content: center; */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: unset;
  min-height: 100vh;
}


.modalHeaderName {
  font-weight: 600;
  font-size: 24px;
  line-height: 133.4%;

}

.statusas {
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.17px;
  margin: unset;
}

.uzsakymoNr {
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
  letter-spacing: 0.15px;
  margin: unset;
}

.row {
  margin: unset;
}


.remember {
  margin: 0 0 5px -10px;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: 600 !important;
  font-size: 16px !important;
}



.sf {
  cursor: pointer;
  width: fit-content;
}

.sf:hover {
  color: var(--main-color);
}

.primary {
  border: none;
  margin: 10px 10px 10px 0;
}

.btn {
  padding: 10px 20px !important;
  border-radius: 50px !important;
  width: 100%;
}

.Nepriskirtas {
  font-weight: 500 !important;
  cursor: pointer;
}

.Atlikta {
  font-weight: 500 !important;
  cursor: pointer;
}

.Atliktas.Apmokėtas {
  font-weight: 500 !important;
  cursor: pointer;
}

.Neapmokėta {
  font-weight: 500 !important;
  cursor: pointer;
}

.Vykdoma {
  font-weight: 500 !important;
  cursor: pointer;
}

.Priskirtas {
  font-weight: 500 !important;
  cursor: pointer;
}

.statusasDropw {
  width: 100%;
  justify-content: end;
  display: flex;
  align-items: center;
}

.naujiena_title {
  font-size: 16px;
  font-weight: 600;
}

.naujiena_subtitle {
  font-size: 16px;
  font-weight: 500;
}

.selectedDarbas .Neapmokėta {
  font-weight: 800 !important;
}

.selectedDarbas .Vykdoma {
  font-weight: 800 !important;
}

.selectedDarbas .Atliktas.Apmokėtas {
  font-weight: 800 !important;
}

.selectedDarbas .Atlikta {
  font-weight: 800 !important;
}

.selectedDarbas .Priskirtas {
  font-weight: 800 !important;
}

.selectedDarbas .Nepriskirtas {
  font-weight: 800 !important;
}

.modal3 {
  width: 20% !important;
  max-width: 90vw !important;
  margin: auto;
  padding: 30px 30px 10px 30px;
  background: var(--main-background);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 15px;
  border-color: var(--main-background);
  text-align: center;
  height: unset !important;
}

.centerContainer1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.modalInfo {
  overflow-y: auto;
  /* white-space: nowrap; */
  margin: 20px 0px 30px 0px;
  font-size: 20px;
}

.modalBtnContainer {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.btnWide {
  padding: 10px 50px !important;
}

.kablukasLent {
  margin-top: 0px;
  max-width: 90vw;
  margin: 0 auto;
  background: white;
  padding: 30px;
  border-radius: 20px;
  width: 640px;
  background-color: var(--main-background);
}


.darbuotojai {
  max-width: 90vw;
  margin: 0 auto 50px auto;
  background: white;
  padding: 30px;
  border-radius: 20px;
  width: 900px;
  background-color: var(--main-background);
}


.pavad {
  border-top: 3px solid var(--main-color);
  padding-top: 10px;
}

.lowWeight {
  cursor: pointer;
  font-weight: 500;
}

.selectedDarbas .lowWeight {
  font-weight: 800;
}

.pointer {
  cursor: pointer;
}

.selectedDarbas .statusas::before {
  content: "✓";
}

.dataGrid {
  background-color: var(--main-background);
}

.left {
  -webkit-box-align: unset;
  -ms-flex-align: unset;
  align-items: unset;
  /* max-width: 500px; */
}

.MuiDataGrid-columnHeader:focus-within {
  outline: none !important;
}

.MuiDataGrid-columnHeader:focus {
  outline: none !important;
}

.opener::after {
  content: url("drop1.png");
}

.opener.open::after {
  content: url("drop2.png");
}

.deletePht {
  background-color: var(--main-color);
  color: var(--main-background);
  border: none;
  /* border-radius: 7px; */
  width: 100%;
  padding: 5px;
  cursor: pointer;
  border: 1px solid var(--main-color);
  z-index: 100;
}

.deletePht:hover {
  background-color: var(--main-background);
  color: var(--main-color);
}

.sc-iBkjds.kXwBue {
  color: var(--main-background);
}

.pliusMinus {
  border: none;
  color: var(--main-color);
  background: none;
  font-weight: 800;
  font-size: 25px;
  padding: 0 18px;
}

.add {
  border: none;
  background-color: var(--main-color);
  color: var(--main-background);
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  margin-left: 10px;
}

ul.panaudotosMedz {
  padding: unset !important;
  margin: 0;
}

.manyImg>div {
  padding: 10px 10px 10px 0;
  display: flex;
  flex-direction: column;
}

.padidinti:hover::before {
  content: url("glass.png");
  color: var(--main-color);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  background-color: #f0f8ff9e;
  align-items: center;
  justify-content: center;
}

.overAll img {
  height: 85vh;
}

.pasirinktiDarbArch {
  margin-bottom: 20px;
  padding: 0 90px;
}

a.politika {
  color: var(--main-color);
  text-decoration: unset;
}

.kalendorius.lentele h1 {
  color: var(--main-background);
}

.rmdp-calendar {
  background-color: var(--main-background);
  border: unset;
  outline: unset;
}

.rmdp-shadow {
  box-shadow: unset !important;
}

.rmdp-week-day {
  color: rgba(0, 0, 0, 0.33) !important;
}

.rmdp-day.rmdp-disabled {
  color: rgba(0, 0, 0, 0.33) !important;
}

.rmdp-day.rmdp-today span {
  background-color: var(--main-color) !important;
  color: var(--main-background) !important;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: var(--main-color) !important;
  box-shadow: unset !important;
  border: 1px solid var(--main-color) !important;
  font-size: 14px;
  font-weight: unset;
  color: white !important;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  background-color: var(--main-background) !important;
  color: var(--main-color) !important;
  /* border: 1px solid var(--main-background) !important; */
  font-size: 14px;
  font-weight: unset;
}

.rmdp-arrow-container.disabled .rmdp-arrow,
.rmdp-arrow-container.disabled:hover .rmdp-arrow {
  border: solid #bfbfbf !important;
  border-width: 0 2px 2px 0 !important;
}

.rmdp-arrow {
  border: solid rgba(0, 0, 0, 0.54) !important;
  border-width: 0 2px 2px 0 !important;
}

.info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  background-color: var(--main-backgroundDark);
  padding: 20px 90px;
}

.infoAsm {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.imagez {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px 90px;
}

.Priskirtas span::after {
  content: "";
  background: #8c7600 !important;
  width: 58px !important;
  height: 5px !important;
  position: absolute;
  bottom: -5px !important;
  left: -23px !important;
}

.Vykdoma span::after {
  content: "";
  background: #f0c60f !important;
  width: 54px !important;
  height: 5px !important;
  position: absolute;
  bottom: -5px !important;
  left: -19px !important;
}

.Planuojama span::after {
  content: "";
  background: #0f78f0 !important;
  width: 54px !important;
  height: 5px !important;
  position: absolute;
  bottom: -5px !important;
  left: -20px !important;
}

.Nepriskirtas span::after {
  content: "";
  background: #bc2222 !important;
  width: 54px !important;
  height: 5px !important;
  position: absolute;
  bottom: -5px !important;
  left: -19px !important;
}

.Atlikta span::after {
  content: "";
  background: #1b792a !important;
  width: 54px !important;
  height: 5px !important;
  position: absolute;
  bottom: -5px !important;
  left: -19px !important;
}

.containerPht {
  position: relative;
  background-color: var(--main-backgroundDark);
  display: flex;
  flex-direction: column;
  width: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.prideti:hover::before {
  content: "+";
  font-size: 50px;
  color: var(--main-color);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  background-color: var(--main-backgroundDark);
  align-items: center;
  justify-content: center;
  cursor: pointer !important;
}

.prideti::before {
  content: "+";
  font-size: 50px;
  color: var(--main-color);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  background-color: var(--main-backgroundDark);
  align-items: center;
  justify-content: center;
  cursor: pointer !important;
}

.prideti {
  cursor: pointer !important;
}

.nieko {
  content: "+";
  font-size: 50px;
  color: var(--main-color);
  /* position: absolute; */
  /* width: 100%; */
  /* height: 100%; */
  /* top: 100%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  display: flex;
  background-color: var(--main-backgroundDark);
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 137.55px;
}

.left h3 {
  margin: 10px 0 9px 0;
}

.left textarea {
  resize: none;
  margin: 0 0 10px 0;
}

.closeBtn:focus-visible {
  outline: unset !important;
}

.passIcon2 {
  position: absolute;
  margin: 20px 0 0 0;
  right: -2%;
  top: 36%;
}



.infoProb {
  display: flex;
  flex-direction: column;
}

.darb {
  /* width: 100%;
  display: flex;
  flex-direction: row; */
  flex-direction: row;
  padding: 0 0px;
  display: grid;
  grid-template-columns: 0fr 1fr;
  padding: 0 90px;
  display: flex;
}

.komentarai {
  background-color: var(--main-backgroundDark);
  padding: 0 90px;
}

.kitasVizitasH2 h2 {
  color: black;
  text-align: left;
}

.kitasVizitas {
  background-color: var(--main-backgroundDark);
  padding: 10px 90px;
}



.addDarb {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.btnSelect {
  background-color: var(--main-color);
  color: var(--main-background);
  border-radius: 20px;
  border: none;
  padding: 10px !important;
  font-size: 15px;
  font-weight: bold;
  height: unset;
}

.choose {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pradeti_pabaigti {
  padding: 0 90px;
  background-color: var(--main-backgroundDark);
}

.summary {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.summary1 {
  display: flex;
  flex-direction: column;
  padding: 10px;
  text-align: center;
  background-color: #e2dfd9;
  min-width: 200px;
  flex: 33%;
}

.summary2 {
  display: flex;
  flex-direction: column;
  padding: 10px;
  text-align: center;
  background-color: var(--main-backgroundDark);
  min-width: 200px;
  flex: 33%;
}

.summary2 h2 {
  color: var(--main-color);
  font-size: 30px;
  font-weight: 800;
}

.summary3 h2 {
  color: var(--main-color);
  font-size: 30px;
  font-weight: 800;
}

.summary3 {
  display: flex;
  flex-direction: column;
  padding: 10px;
  text-align: center;
  background-color: #e2dfd9;
  min-width: 200px;
  flex: 33%;
}

.arapmoketa {
  padding: 0 90px;
}

.darbBtn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 90px;
  flex-direction: row;
  justify-content: center;
  height: 100%;
}

.sign {
  padding: 0 90px;
}

.modalaz {
  display: flex;
  flex-direction: column;
}

.padding {
  padding: 0 90px;
  display: flex;
}

.selectedDarbas.fullFlex.padding {
  display: flex;
  cursor: pointer;
}

.selectDropdown {
  border-top: 2px solid var(--main-color);
}

.rodykle::after {
  content: url("drop2.png");
  margin: 0 0 0 5px;
}

.rodykle2::after {
  content: url("drop1.png");
  margin: 0 0 0 5px;
}

.ReactModal__Content.ReactModal__Content--after-open.modal2:focus-visible {
  outline: none;
}

.planuotiVizita {
  display: flex;
  flex-direction: column;
  padding: 0 90px;
}

.selectDarbasCont.selectas {
  border-bottom: 2px solid var(--main-color);
}

.sfRow {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding: 10px 0; */
  font-size: 15px;
}

.medziagu {
  padding: 0 90px;
}

.ReactModal__Content.ReactModal__Content--after-open.modal2.timePicker {
  padding: 90px !important;
}

label.neperkrauti {
  font-size: 16px;
}

.row.spaceBetween.center.wrap {
  margin-bottom: 20px;
}

.MuiDataGrid-cell.MuiDataGrid-cell--textLeft {
  cursor: context-menu;
}

.MuiDataGrid-cell.MuiDataGrid-cell--textLeft img {
  cursor: pointer !important;
}

.summary h3 {
  /* border-bottom: 2px solid var(--main-color); */
  text-align: center;
}

.sign1 {
  display: flex;
  flex-direction: column;
}

.parasasImg img {
  max-height: 75%;
  max-width: 75%;
}

.parasasImg {
  width: 100%;
  height: 130px;
  border-radius: 20px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign1 button {
  height: 40%;
}

.pagrindiniaiMyg {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: #d9d9d9;
  padding: 20px 90px;
}

.redaguotiApmokejimaRadiobtnCont {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0px 90px 20px 90px;
}

label.redaguotiApmokejimaRadiobtn {
  display: flex;
  flex-direction: row;
  background-color: var(--main-color);
  padding: 10px 20px;
  border-radius: 20px;
  color: var(--main-background);
  text-transform: uppercase;
  align-items: flex-start;
  cursor: pointer;
  font-size: 14px;
}

.keistMokejimaCont {
  display: flex;
  flex-direction: column;
  padding: 10px 90px 20px 90px;
  background-color: #d9d9d9;
}


.pagrindiniaiMyg .btn {
  text-transform: uppercase;
  font-size: 12px;
  width: 168px;
  height: 50px;
}

.ReactModal__Content.ReactModal__Content--after-open.modal2.timePicker {
  height: unset;
}

.issaugoti {
  padding: 10px 90px;
  /* background-color: #D9D9D9; */
}

.darbolaikas {
  text-transform: uppercase;
}

.laikmatis {
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  background-color: #d9d9d9;
  padding: 10px 90px;
}

.laikmatis h3 {
  text-align: left;
  color: black;
  text-transform: uppercase;
  font-weight: 600;
  height: unset;
  margin: unset;
  padding: unset;
}

.laikmatis h1 {
  text-align: left;
  color: var(--main-color);
  text-transform: uppercase;
  font-weight: 600;
  height: unset;
  margin: unset;
  padding: unset;
  font-size: 50px;
}

.signAndPay {
  background-color: #d9d9d9;
  padding: 1px 0 10px 0;
}

/* 
.userPicker {
  padding: 0 90px;
} */

li.row.panaudotosMedziagos2 {
  display: flex;
  grid-auto-flow: flex;
  grid-template-columns: 100% 1fr 1fr 1fr;
  gap: 30px;
  border-bottom: 2px solid var(--main-color);
  flex-wrap: nowrap;
}

li.row.panaudotosMedziagos2 p:first-of-type {
  flex: 100%;
}

p.panMedzLent {
  /* text-align: center!important; */
  width: 14%;
}

canvas {
  width: 100%;
  height: 130px;
  border-radius: 20px;
  background: white;
}

.modalImg {
  display: flex;
  align-items: flex-end;
  /* min-width: 100px */
}

.MuiDataGrid-virtualScrollerContent {
  background-color: var(--main-background);
  z-index: 0;
}

.kablukoMedziagosPavad {
  display: flex;
  flex-direction: row;
}

p.td {
  display: table-cell;
  width: 13%;
  text-align: left;
  color: var(--main-color);
  /* border-left: 1px solid #b8b7b2; */
  border-bottom: 1px solid #b8b7b2;
}

p.td2 {
  display: table-cell;
  width: 60%;
  text-align: left;
  color: var(--main-color);
  border-bottom: 1px solid #b8b7b2;
  padding: 10px 0;
}

p.td3 {
  display: table-cell;
  width: 15%;
  text-align: left;
  color: var(--main-color);
  /* border-left: 1px solid #b8b7b2; */
  border-bottom: 1px solid #b8b7b2;
}

p.td4 {
  display: table-cell;
  width: 70%;
  text-align: left;
  color: var(--main-color);
  border-bottom: 1px solid #b8b7b2;
  padding: 10px 0;
}

p.th {
  display: table-cell;
  padding: 10px 0;
  text-align: left;
  font-weight: 600;
}

p.th2 {
  display: table-cell;
  padding: 10px 0;
  text-align: center;
  font-weight: 600;
  width: 175px;
}

p.th3 {
  display: table-cell;
  padding: 10px 0;
  text-align: center;
  font-weight: 600;
  width: 28%;
}

.pilka {
  background-color: #e2dfd9;
}

.pilka2 {
  background-color: #d9d9d9;
  border-left: 1px solid #b8b7b2;
  border-right: 1px solid #b8b7b2;
}

button.xDelete {
  border: none;
  font-size: 23px;
  background-color: transparent;
  color: var(--main-color);
  cursor: pointer;
}

.pridetiMedz .MuiFormControl-root.MuiTextField-root {
  margin: 10px 0 !important;
  width: 100%;
}

.darbo.laikas {
  background-color: #d9d9d9;
  padding: 1px 90px 20px 90px;
}

.darbo.laikas h3 {
  text-transform: uppercase;
}

.smallPadd {
  padding: 20px 90px 0 90px !important;
}

.liveLaikas {
  color: var(--main-color);
  font-weight: 800;
  font-size: 40px;
}

.marginBtn {
  margin: 50px 0 0 0;
}

.selectedDarbas.row.fullFlex::after {
  content: url("drop1.png");
  padding: 0 0 0 10px;
}

.selectedDarbas.row.fullFlex.dropdownRodykle::after {
  content: url("drop2.png");
  padding: 0 0 0 10px;
}

.marginBtn2 {
  margin: 20px !important;
}

.marginUnset {
  margin: unset !important;
}

span.sd.worked {
  color: var(--main-color) !important;
  font-weight: 800;
  font-size: 19px;
}

.lentelesSumm h4 {
  margin: 0 10px 0 0;
}

.lentelesSumm {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  flex-direction: column;
}

.pradirbtasLaikasSumm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bendraSumaSumm {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-multiline {
  display: flex;
  flex-direction: column;
}

.infoCont {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  height: 100%;
  background-color: var(--main-backgroundDark);
  padding: 0 90px;
}

.infoVid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-direction: row;
  justify-content: center;
  /* height: 100%; */
  background-color: var(--main-backgroundDark);
  /* padding: 0 90px; */
}

.papildoma {
  display: flex;
  flex-direction: column;
}

.bold {
  font-weight: 800;
}

.deleteImg img {
  width: 25%;
}

textarea {
  border-radius: unset;
}

.dataGridSearch:hover {
  -webkit-filter: invert(0.5);
  filter: invert(0.5);
}

.dataGridSearch {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
}

.dataGridSearch img {
  height: 30px;
}

.btnPay {
  width: 90%;
}

.btnPay2 {
  margin: 5px !important;
}

.atsauktBtn {
  width: 38% !important;
}

.rodyti_zemelapy {
  text-decoration: none;
  color: var(--main-color);
  margin: 0 0 0 11px;
}

.rodyti_zemelapy:focus {
  outline: none;
}

.btn.primary:focus-visible {
  outline: none;
}

.kablukasPriskirt {
  display: flex;
  flex-direction: row;
}

.pasirinkti_darbuotoja {
  display: flex;
  flex-direction: column;
}

.pasirinkti_darbutoja_name {
  text-align: center;
}

.pridetiMok {
  font-size: 10px;
  width: fit-content;
}

/* 
.archyvuotiWidth {
  width: 810px;
} */

.paddingasPickeriui {
  padding: 10px 90px;
}

/* .SFselect {
  background-color: var(--main-color);
  box-shadow: none;
  border-color: var(--main-color);
  color: var(--main-background);
} */

.pridetiBtn:disabled {
  color: white !important;
}

.widthProc {
  width: 80%;
}

.widthProc2 {
  width: 40%;
}

.reverseColorBtn {
  padding: 10px !important;
  color: black !important;
  background-color: var(--main-background) !important;
}

.alignRight {
  align-items: flex-start !important;
}


/* SU CSS- */

.control {
  border: 1px solid #b8b7b2 !important;
  border-color: #b8b7b2 !important;
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
  background-color: transparent !important;
  padding: 9.5px 14px !important;
}

.control:hover {
  border: 1px solid #b8b7b2 !important;
  border-color: #b8b7b2 !important;
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
  background-color: transparent !important;
  padding: 9.5px 14px !important;
}

.css-1s2u09g-control {
  padding: 9.5px 14px;
  background-color: transparent !important;
}

.css-319lph-ValueContainer {
  padding: unset !important;
}

.css-r11z79-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  width: unset;
}

.userPicker .selected .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.Mui-disabled.MuiInputBase-formControl.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  border: 1px solid var(--main-color);
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiDataGrid-iconSeparator.css-i4bv87-MuiSvgIcon-root {
  display: none;
}

.MuiDataGrid-columnHeaders.css-f3jnds-MuiDataGrid-columnHeaders {
  border-bottom: 3px solid var(--main-color) !important;
  z-index: 10;
}

.css-1xy1myn-MuiDataGrid-root .MuiDataGrid-columnHeader--sortable {
  outline: unset !important;
}


.kablukasLent .MuiDataGrid-footerContainer.css-17jjc08-MuiDataGrid-footerContainer {
  display: none;
}

.darbuotojai .MuiDataGrid-footerContainer.css-17jjc08-MuiDataGrid-footerContainer {
  display: none;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiAlert-root.MuiAlert-outlinedSuccess.MuiAlert-outlined.css-ucjyav-MuiPaper-root-MuiAlert-root {
  color: var(--main-background) !important;
  border: 2px solid var(--main-color) !important;
  background-color: var(--main-color) !important;
}

.css-ucjyav-MuiPaper-root-MuiAlert-root .MuiAlert-icon {
  color: var(--main-background) !important;
}

.komentarai .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-multiline.css-14rdlss-MuiInputBase-root-MuiOutlinedInput-root {
  width: 120%;
}

@keyframes pranykt {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiAlert-root.MuiAlert-outlinedSuccess.MuiAlert-outlined.css-dcrfqy-MuiPaper-root-MuiAlert-root {
  position: absolute;
  bottom: 20px;
  z-index: 99999;
  animation: forwards pranykt 3s ease-out;
}

.kategorijosImg {
  display: flex;
  align-items: center;
}


img.Elektra {
  padding: 0 5px;
  width: 6px !important;
}

img.Šildymas {
  padding: 0 5px;
  width: 9px !important;

}

img.Kondicionavimas {
  padding: 0 5px;
  width: 14px !important;
}

img.Vėdinimas {
  padding: 0 5px;
  width: 20px !important;
}

img.Santechnika {
  padding: 0 5px;
  width: 10px !important;

}

.MuiDataGrid-cell.MuiDataGrid-cell--textLeft {
  cursor: pointer !important;
}

.atidaryta_naujiena {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}