.closeBtn {
  position: absolute;
  top: 11.5px;
  right: 11.5px;
  background-color: transparent;
  border: none;
  color: var(--main-color);
  font-size: 28px;
  font-family: revert;
}

.closeBtnMod {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  border: none;
  color: var(--main-color);
  font-size: 28px;
  font-family: revert;
}

.closeBtnKab {
  position: absolute;
  top: -30px;
  right: 0;
  background-color: transparent;
  border: none;
  color: var(--main-color);
  font-size: 28px;
  font-family: revert;
}

.modalTexts {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 100px 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.modalTexts > * {
  width: 43%;
  min-width: 260px !important;
  margin-bottom: 20px !important;
}
.fullFlex {
  -webkit-box-flex: 100%;
  -ms-flex: 100%;
  flex: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.modal {
  background: white;
  /* padding: 90px !important; */
  border-radius: 20px;
  /* max-width: 824px; */
  max-height: 1026px;
  height: 70vh;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;

  overflow: auto;
}

.modal2 {
  background: white;
  padding: 20px 0 90px 0 !important;
  border-radius: 20px;
  max-width: 740px;
  min-width: 740px;
  max-height: 1026px;
  height: 70vh;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;

  overflow: auto;
}

.selectedDarbas.row.fullFlex {
  background: #ff990026;
}
.modal .btn {
  max-width: 240px;
  border: none;
}
.modal .btn-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin: 20px 0;
}
.modal h2 {
  color: black;
  text-align: left;
}

/* Time picker */
.modal.timePicker {
  background-color: var(--main-color);
  height: unset;
  width: 70vw;
  height: 70vh;
  max-height: 450px;
  max-width: 600px;
}
.isvietimasData {
  width: 70%;
}

.iskvietimasLaikas {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin: 20px 0;
  font-size: 10px;
}
.TimePickerContainer {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  /* -ms-flex-wrap: wrap; */
  /* flex-wrap: wrap; */
}

.laikasBtn {
  border-radius: 20px;
  padding: 8px;
  border: 1px;
  border-color: black;
  /* background-color: var(--font-color); */
  border: 1px solid var(--main-color) !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  color: var(--main-color);
  font-size: 14px;
  margin: 5px;
}
.laikasBtn:hover {
  border: 1px solid var(--main-priskirtas) !important;
  color: var(--main-background) !important;
  background-color: var(--main-priskirtas) !important;
}

.laikasBtn.active {
  border: 1px solid var(--main-priskirtas);
  color: var(--main-background) !important;
  background-color: var(--main-priskirtas);
}
.miniM {
  margin-right: 10px;
}
